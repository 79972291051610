/**
 * External dependencies.
 */
import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Pagination from '@mui/material/Pagination';
import Modal from '@mui/material/Modal';
import { useState, useEffect } from "react";

/**
 * Internal dependencies.
 */
import LayoutAlt from 'layouts/layout-alt/layout-alt';
import WidgetCircle from 'components/widget-circle/widget-circle';
import SectionImage from 'assets/images/img.png';
import Article from 'components/article/article';
import ModalNew from 'components/modal-new/modal-new';

import { requireAuthToken, requireUserID } from 'MyUtils.js'


const useStyles = makeStyles(theme => createStyles({
	section: {
		position: 'relative',
		zIndex: '10',
		height: 'calc(100vh - 64px)',
		overflowY: 'auto',
		overflowX: 'hidden',
		padding: '100px 0 35px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		[theme.breakpoints.down('md')]: {
			display: 'block',
			padding: '150px 0 70px',
		},
		[theme.breakpoints.down('xs')]: {
			padding: '78px 0 70px',
		},
		'& > div': {
			flex: '0 0 100%'
		}
	},
	sectionHasCircles: {
		position: 'relative',
		zIndex: '10',
		height: 'calc(100vh - 64px)',
		overflowY: 'auto',
		overflowX: 'hidden',
		padding: '129px 0 35px',
		[theme.breakpoints.down('md')]: {
			padding: '150px 0 70px',
		},
		[theme.breakpoints.down('xs')]: {
			padding: '54px 0 70px',
		},
	},
	sectionContent: {
		textAlign: 'center',
		maxWidth: '482px',
		margin: 'auto',
		position: 'relative',
		left: '155px',
		[theme.breakpoints.down('md')]: {
			margin: '0 0 40px',
			left: '0',
		},
		[theme.breakpoints.down('sm')]: {
			margin: '0 auto 40px',
		},
		[theme.breakpoints.down('xs')]: {
			margin: '0 auto 16px',
		}
	},
	image: {
		margin: '0 0 43px',
		[theme.breakpoints.down('xs')]: {
			width: '215px',
			margin: '0 0 25px',
		}
	},
	sectionTitle: {
		letterSpacing: '0',
		margin: '0 0 23px',
		color: '#808191',
		fontWeight: '600',
		[theme.breakpoints.down('xs')]: {
			fontSize: '14px',
			margin: '0 0 11px',
		}
	},
	sectionEntry: {
		fontSize: '20px',
		fontWeight: '500',
		lineHeight: '1.65',
		letterSpacing: '0',
		[theme.breakpoints.down('xs')]: {
			fontSize: '11px',
			padding: '0 20px',
		}
	},
	sectionBox: {
		margin: 'auto',
		left: '-33px',
		[theme.breakpoints.down('md')]: {
			left: '0'
		}
	},
	sectionBar: {
		textAlign: 'center',
		// marginBottom: '70px',
		[theme.breakpoints.down('xs')]: {
			position: 'fixed',
			bottom: '97px',
			right: '19px',
			marginBottom: '0',
			zIndex: '100',
		}
	},
	articles: {
		margin: '0 -77px',
		[theme.breakpoints.down('lg')]: {
			margin: '0',
		},
		[theme.breakpoints.down('xs')]: {
			margin: '0 -15px',
		}
	},
	articlesActions: {
		marginBottom: '10px',
		paddingLeft: '117px',
		[theme.breakpoints.down('xs')]: {
			paddingLeft: '40px',
			marginBottom: '8px',
		},
		'& button': {
			[theme.breakpoints.down('xs')]: {
				fontSize: '12px',
			},
			'& i': {
				[theme.breakpoints.down('xs')]: {
					width: '10px',
					height: '10px',
				}	
			}
		}
	},
	sectionPagination: {
		// marginTop: '90px',
		[theme.breakpoints.down('xs')]: {
			margin: '90px -15px 0'
		},
		'& .MuiPagination-ul': {
			justifyContent: 'center'
		},
		'& .MuiButtonBase-root': {
			width: '34px',
			height: '34px',
			lineHeight: '34px',
			fontSize: '16px',
			border: 'none',
			background: '#fff',
			margin: '0 5px',
		},
		'& Mui-disabled': {
			background: '#fff'
		},
		'& .Mui-selected': {
			backgroundColor: '#00CCEE !important',
			color: '#fff',
			borderRadius: '50%',
		},
		'& li:last-child': {
			'& button': {
				background: 'none'
			}
		}
	},
	sectionButton: {
		[theme.breakpoints.down('xs')]: {
			fontSize: '0',
			minWidth: '0',
			width: '56px',
			height: '56px',
			padding: '0',
			borderRadius: '50%',
		},
		'& i': {
			display: 'none',
			position: 'absolute',
			top: '50%',
			left: '50%',
			transform: 'translate(-50%, -50%)',
			[theme.breakpoints.down('xs')]: {
				display: 'inline-block',
			},
		}

	},
	arrowIcon: {
		transition: '0.5s'
	},
	arrowIconDown: {
		transform: "rotate(180deg)"
	}

}));

function randomAvatars() {
	let avatars = [{	
						name: "Avatar 2",
						image: require('assets/images/av2.jpg')
					},
					{	
						name: "Avatar 3",
						image: require('assets/images/av3.jpg')
					},
					{	
						name: "Avatar 4",
						image: require('assets/images/av4.jpg')
					},
					{	
						name: "Avatar 5",
						image: require('assets/images/av5.jpg')
					}];

	/* avatars.sort(() => Math.random() - 0.5); // shuffle semi-random

	return avatars.slice(0, Math.floor(Math.random() * 4)); */
	return avatars
}

const Circles = () => {
	const classes = useStyles();
	const [openModal, setOpenModal] = useState(false);
	const [page, setCurrentPage] = useState(1);
	const [sortType, setSortType] = useState(classes.arrowIconDown);
	const maxPerPage = 5;
	const [articles, setArticles] = useState(() => {
		let aux = [];
		for (let i = 0; i < maxPerPage; i++) {
			aux.push({
				image: "loading.jpg",
				name: "loading",
				avatars: [],
				skeleton: true
			});
		}
		return aux;
	});


	useEffect(()=>{
		async function fetchCircles() {
			const authToken = await requireAuthToken(false, false);
			const userId = requireUserID(true);
			const response = await fetch(`${process.env.REACT_APP_ENDPOINT}/room/${process.env.REACT_APP_GAMELIFT}`, { //?Creator=${userId}
     
				// Adding method type
				method: "GET",
				 				 
				// Adding headers to the request
				headers: {
					"Content-type": "application/json; charset=UTF-8",
					"Authorization": `Bearer ${authToken}`
				}
			});

			if (!response.ok) {
				if (response.status == 401) {
					window.location.replace("/");
				}
				throw new Error(`HTTP error! status: ${response.status}`); // handle errors
			}

			const data = await response.json();

			//console.log(data)

			let auxArticles = [];
			if (data.Rooms.length > 0) {
				data.Rooms.forEach(element => {
					if (element.CreatedBy === userId) {
						auxArticles.push({
							image: require('assets/images/a3.png'),
							name: element.RoomName,
							roomId: element.RoomId,
							avatars: [ 
								{	
									name: "Avatar Name",
									image: require('assets/images/av2.jpg')
								},
								{
									name: "Avatar Image",
									image: require('assets/images/av3.jpg')
								}
							]
						});

						return false; // break
					}

					return true; // keep iterating
				});
				auxArticles.sort((a, b) => a.name > b.name)
			}
			setArticles(auxArticles);				
		};

		fetchCircles();
	}, [])
	
	useEffect(()=>{
		//console.log("Reorder circles " + sortType)
		let auxArticlesTwo = articles;
		switch (sortType) {
			case "":
				auxArticlesTwo.sort((a, b) => a.name < b.name)
				break;
				default:
					auxArticlesTwo.sort((a, b) => a.name > b.name)
		}
		setArticles([...auxArticlesTwo])
	}, [sortType])
	// Display one page or another depending on response
	if (articles.length > 0) {
		return (
			<LayoutAlt activeNav="círculos">
				<Box className={classes.sectionHasCircles}>
					<Box>
						<Container maxWidth="xl">
							<Box className={classes.sectionBar}>
								<Button 
									variant="contained" 
									color="secondary" 
									className={classes.sectionButton}
									onClick={(e) => { 
										e.preventDefault()
	
										setOpenModal(!openModal) 
									}}
								>
									Nuevo círculo
	
									<i className="ico-plus"></i>
								</Button>
							</Box>
	
							<Box className={classes.articles}>
								<Box className={classes.articlesActions} onClick={() => {
										switch (sortType) {
											case "":
												setSortType(classes.arrowIconDown);
												break;
											default:
												setSortType("");
										}
									}}>
									<Button variant="text" >Nombre <i className={"ico-arrow-up " + classes.arrowIcon + " " + sortType}></i></Button>
								</Box>
	
								{articles?.slice(maxPerPage * (page - 1), maxPerPage * page).map((article, index) => {
									return (
										<Article
											key={index}
											image={article.image}
											name={article.name}
											avatars={article.avatars}
											skeleton={article.skeleton}
											roomId={article.roomId}
										/>
									) 
								})}
							</Box>
							<Pagination 
								count={Math.ceil(articles?.length / maxPerPage)} 
								variant="outlined" 
								shape="rounded" 
								className={classes.sectionPagination}
								page={page}
								onChange={(e, p) => {
									setCurrentPage(p);
								}}
							/>
							
							
						</Container>
					</Box>
				</Box>
				
				<Modal
					open={openModal}
					onClose={(e) => { setOpenModal(false) }}
				>
					<div><ModalNew onClose={(e) => { setOpenModal(false) }} /></div>
				</Modal>
			</LayoutAlt>
		);						
	} else {
		return (
			<LayoutAlt>
				<Box className={classes.section}>
					<Box>
						<Container maxWidth="xl">
							<Grid container spacing={2} alignItems="center">
								<Grid item md={6} xs={12}>
									<Box className={classes.sectionContent}>
										<img 
											className={classes.image}
											src={SectionImage} 
											alt=""
										 />
										
										<Typography 
											variant="h4" 
											component="h4"
											className={classes.sectionTitle}
										>
											Aún no has creado ningún círculo.
										</Typography>
	
										<Typography 
											component="p"
											className={classes.sectionEntry}
										>
											Los círculos son salas o <strong>espacios virtuales</strong> donde invitar a usuarios específicos.
										</Typography>
									</Box>
								</Grid>
	
								<Grid item md={6} xs={12}>
									<WidgetCircle 
										modifier={classes.sectionBox} 
										url="/"
										buttonText="Crear círculo"
										onClick={(e) => { 
											e.preventDefault()
		
											setOpenModal(!openModal) 
										}}
									/>
								</Grid>
							</Grid>
						</Container>
					</Box>
				</Box>
				<Modal
					open={openModal}
					onClose={(e) => { setOpenModal(false) }}
				>
					<div><ModalNew onClose={(e) => { setOpenModal(false) }} /></div>
				</Modal>

			</LayoutAlt>
		);
	}

};

export default Circles;
